// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const signInConfig = {
  WEB_PROTOCOL:'https://',
  DOMAIN_NAME: 'sin-inquiry-uat.auth.ap-southeast-1.amazoncognito.com',
  CLIENT_ID: '2eble18g59cugh1104m63uacum',
  RESPONSE_TYPE: 'token',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  REDIRECT_SIGN_IN: 'https://sin-inquiry-uat.meco-soldel.com/login',
  REDIRECT_SIGN_OUT: 'https://sin-inquiry-uat.meco-soldel.com/',
  SCOPE: ['email', 'openid'],
}

export const environment = {
  production: false,

  homepage: '/sin-inquiry/view',
  hostURL: 'https://sininquiry-uat-api.meco-soldel.com/uat',

  // AMPLIFY environments
  domainName: `${signInConfig.DOMAIN_NAME}`,
  clientId: `${signInConfig.CLIENT_ID}`,
  responseType: `${signInConfig.RESPONSE_TYPE}`,
  redirectUri: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignIn: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignOut: `${signInConfig.REDIRECT_SIGN_OUT}`,
  scope: `${signInConfig.SCOPE}`,
  signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=email+openid&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?client_id=${signInConfig.CLIENT_ID}&logout_uri=${signInConfig.REDIRECT_SIGN_OUT}`
};